<template>
  <div class="death-rate-table mt-20">
    <a-table :columns="columns" :data-source="deathData" :pagination="false" size="small"/>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'MortalityDeathRateTable',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      isExhaustive: state => state.survey?.metadata?.samplingMethod === 'Exhaustive'
    }),
    columns() {
      const columns = [
        {
          title: '',
          dataIndex: 'title',
          key: 'title'
        },
        {
          title: this.isExhaustive ? this.$t('components.titles.deathRate') : this.$t('components.titles.deathRateCI'),
          dataIndex: 'rate',
          key: 'rate',
          sorter: (a, b) => a.rateValue - b.rateValue
        },
        {
          title: this.$t('components.titles.designEffect'),
          dataIndex: 'effect',
          key: 'effect',
          sorter: (a, b) => parseFloat(a.effect) - parseFloat(b.effect)
        }
      ];
      return columns;
    },
    deathData() {
      return this.data.map((data, index) => ({
        key: index + 1,
        title: this.levelToDisplay(data.level),
        rate: `${
          data.stat != null ? data.stat.toFixed(2) : 0
        } ${data.stat_low != null
          ? `(${
            data.stat_low.toFixed(2)
          }-${
            data.stat_upp.toFixed(2)
          })`
          : ''
        }`,
        rateValue: data.stat,
        effect: data.stat_deff ? data.stat_deff.toFixed(2) : 0
      }));
    }
  },
  methods: {
    levelToDisplay(level) {
      const lowercaseLevel = level && level.toLowerCase();
      if (lowercaseLevel === 'f') {
        return this.$tc('values.female', 1);
      }
      if (lowercaseLevel === 'm') {
        return this.$tc('values.male', 1);
      }
      if (lowercaseLevel === 'overall') {
        return this.$t('components.labels.overall');
      }
      return level;
    }
  }
};
</script>

<style lang="scss">

.death-rate-table {

  .ant-table-body table {
    border-top: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
  }

  tbody td:first-child {
    color: #363534;
    font-size: 13px;
    font-family: 'DINNextLTPro-Medium';
    text-transform: uppercase;
    background: #f5f5f5;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-small {
    border: none;
  }
}

</style>
